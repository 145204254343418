.container {
  display: flex;

  .banner {
    width: 48%;
  }
  .form {
    width: 52%;
  }
}
