.container {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .brand-container {
    margin: 8px 12px;
    display: flex;
    align-items: center;
    .org-name {
      color: #0a4834;
      background: #eee4be;
      border: 1px solid #0a4834;
      border-radius: 20px;
      padding: 6px 12px;
      font-size: 12px;
      margin-left: 48px;
    }
  }
  .user-container {
    margin: 8px 12px;
    display: flex;
    align-items: center;
    .profile-photo {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
    }
    .name {
      color: #9a9a9a;
      font-size: 14px;
      margin-left: 8px;
    }
  }
}
