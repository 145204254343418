.label {
  color: #000;
  font-size: 14px;
  margin-bottom: 8px;
}

.input {
  width: 100%;
  margin: 0;
  padding: 6px;
  border: 2px solid #b3b3b3;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  &:active,
  &:focus-visible {
    border: 2px solid #463cff;
  }
  &::placeholder {
    color: #b3b3b3;
    font-size: 14px;
  }
  &::-ms-input-placeholder {
    color: #b3b3b3;
    font-size: 14px;
  }
}
