.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  height: 26px;
  .dropdown-holder {
    display: flex;
    align-items: center;
    .title {
      font-size: 16px;
      margin-right: 8px;
      font-weight: 700;
    }
  }
  .cta-holder {
    display: flex;
    align-items: center;
    .cta {
      margin-left: 8px;
      height: 24px;
      font-size: 12px;
      width: 120px;
    }
    .ocr-icon {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }
}

.content-holder {
  background-color: #fff;
  width: calc(100% - 2px);
  height: calc(100% - 40px);
  border-radius: 8px;
  border: 1px solid #6d6d6d;
  display: flex;
  .uploader-container {
    width: calc(50% - 18px);
    margin: 12px 6px 12px 12px;
  }
  .uploaded-holder {
    border: 8px solid #e7e7e7;
    width: 100%;
    height: 50%;
    object-fit: contain;
    &:first-child {
      border-bottom: 4px solid #e7e7e7;
    }
    &:last-child {
      border-top: 4px solid #e7e7e7;
    }
  }
  .uploaded-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
  .uploader-holder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .uploader-text {
      margin: 20px 0 12px;
      color: #0b0b0b;
      font-size: 14px;
    }
    .or-holder {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      .line {
        border-top: 1px solid #e7e7e7;
        width: 60px;
      }
      .or-text {
        font-size: 12px;
        color: #6d6d6d;
        margin: 0 8px;
      }
    }
    .upload-error {
      color: #ec221f;
      font-size: 14px;
      margin-top: 32px;
    }
  }
  .form-holder {
    width: calc(50% - 18px);
    margin: 12px 12px 12px 6px;
    .title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 20px;
    }
    .ocr-data-holder {
      height: calc(100% - 40px);
      width: 100%;
      overflow-y: scroll;
    }
    .info-label {
      text-transform: capitalize;
    }
    .info {
      margin-bottom: 12px;
    }
  }
}

.loader-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
