.container {
  background: #0a4834;
  position: relative;
  display: flex;
  align-items: center;
  padding: 36px;
  .top-left-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 149px;
    height: 138px;
  }
  .bottom-right-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 307px;
    height: 170px;
  }
  .content-holder {
    width: 100%;
    .title {
      margin: 16px 0;
      font-weight: 600;
      font-size: 40px;
      color: #fff;
      span {
        color: #d5bb5c;
      }
    }
    .subtitle {
      color: #fff;
      font-size: 20px;
    }
  }
}
