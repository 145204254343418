.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  overflow: hidden;
}

.sidebar {
  position: absolute;
  left: 0;
  top: 60px;
  height: calc(100% - 60px);
  width: 200px;
  overflow: hidden;
}

.content-holder {
  position: absolute;
  top: 60px;
  left: 212px;
  width: calc(100% - 224px);
  height: calc(100% - 72px);
  overflow: hidden;
}
