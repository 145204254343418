.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: #f5f5f5;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
