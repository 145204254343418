.container {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .tabs-holder {
    margin: 12px;
  }
  .logout-cta {
    margin: 12px;
  }
  .active-tab-item,
  .tab-item {
    padding: 6px 8px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .tab-name {
      color: #0a4834;
      font-size: 14px;
      margin-left: 8px;
    }
  }
  .tab-item {
    &:hover {
      background-color: #dddada;
    }
  }
  .active-tab-item {
    border-radius: 4px;
    background-color: #eee4be;
    border-right: 4px solid #0a4834;
  }
}
