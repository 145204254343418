body {
  margin: 0;
  font-family: Poppins, serif;
  line-height: 1;
  font-size: 14px;
}

input,
div {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  overflow: hidden;
  line-height: 1.4;
}
