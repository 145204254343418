.container {
  display: flex;
  align-items: center;
  padding: 60px;
  background: #fff;
  .content-holder {
    width: 100%;
    .greeting {
      font-size: 20px;
      margin-bottom: 8px;
    }
    .title {
      font-size: 32px;
      margin-bottom: 12px;
      font-weight: 700;
    }
    .subtitle {
      color: #757575;
      font-size: 14px;
      margin-bottom: 32px;
    }
    .input {
      width: 100%;
      margin: 16px 0;
    }
    .login-button {
      width: 100%;
      margin-top: 24px;
    }
    .error-text {
      margin-top: 12px;
      font-size: 12px;
      color: #ec221f;
    }
  }
}
