.modal {
  border-radius: 8px;
  padding: 20px;
  width: 600px;
}

.delete-modal-title {
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: 500;
}

.delete-cta-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .cta {
    width: 40%;
  }
}

.detail-modal-entry {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  .key {
    font-size: 12px;
    width: 20%;
    text-transform: capitalize;
  }
  .value {
    font-size: 14px;
    font-weight: 500;
    width: 80%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  height: 26px;
  .dropdown-holder {
    display: flex;
    align-items: center;
    .title {
      font-size: 16px;
      margin-right: 8px;
      font-weight: 700;
    }
  }
  .cta-holder {
    display: flex;
    align-items: center;
    .cta {
      margin-left: 8px;
      height: 24px;
      font-size: 12px;
    }
    .ocr-icon {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }
}

.content-holder {
  background-color: #fff;
  width: calc(100% - 2px);
  height: calc(100% - 40px);
  border-radius: 8px;
  border: 1px solid #6d6d6d;
  overflow-y: scroll;
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.ocr-header,
.ocr-item {
  width: 100%;
  border-bottom: 1px solid #6d6d6d;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  .column,
  .column-small {
    padding: 8px 12px;
    width: 30%;
  }
  .column-small {
    width: 10%;
  }
  .delete-cta {
    cursor: pointer;
    width: 16px;
    height: 18px;
  }
}

.ocr-item:hover {
  background: #dcdcdc;
}

.ocr-header {
  background: #dcdcdc;
}
